/*
 * All existing productsTypes
 */

const payRollLoan = {
  id: 1,
  name: "EMPRESTIMO_CONSIGNADO",
  description: "Empréstimo Consignado"
};

const salaryAdvance = {
  id: 2,
  name: "ANTECIPACAO_SALARIO",
  description: "Antecipação de Salário"
};

const debtPurchase = {
  id: 3,
  name: "COMPRA_DIVIDA",
  description: "Compra de Dívida"
};

const refinancing = {
  id: 4,
  name: "REFINANCIAMENTO",
  description: "Refinanciamento"
};

const easyBuyGooroo = {
  id: 5,
  name: "COMPRA_FACIL_CONSIGNADO",
  description: "Gooroo Compra Fácil Consignado"
};
/*
 * Enum
 */

export const ProductsTypeEnum = {
  PAYROLL_LOAN: payRollLoan,
  SALARY_ADVENCE: salaryAdvance,
  DEBT_PURCHASE: debtPurchase,
  REFINANCING: refinancing,
  EASY_BUY_GOOOROO: easyBuyGooroo
};

